<template>
  <div class="">
    <div v-if="$route.query.school=='xwzz'" class="page_daInquiry flexCloumnSingleCenter">
      <div v-if="!isContent&&!info.STU_NAME" class="cell_group">
        <div class="cell_item">
          <div class="item_title">所在班级</div>
          <div class="item_value">
            <el-input v-model="query.className" placeholder="所在班级（例：16信息1班）"></el-input>

          </div>
        </div>
        <div class="cell_item">
          <div class="item_title">姓名</div>
          <div class="item_value">
            <el-input v-model="query.stuName" placeholder="姓名"></el-input>

          </div>
        </div>
        <div class="cell_item flexBetween">
          <div class="queryBtn flexCenter" @click="getList(1)">查询</div>
        </div>
      </div>

      <div v-if="info.STU_NAME" class="cell_group"
           style="margin-top: 20px;padding-bottom: 100px;">
        <div v-if="type==1" class="" style="width: 100%;">
          <div class="groupTitle flexStart">
            <div class="border"></div>
            档案查询信息
          </div>
          <div v-if="info.STU_NUM" class="cell_item">
            <div class="item_title">学号</div>
            <div class="item_value">{{ info.STU_NUM }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">所在班级</div>
            <div class="item_value">{{ info.class_name }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">姓名</div>
            <div class="item_value">{{ info.STU_NAME }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">档案接收单位名称/接收人</div>
            <div class="item_value">{{ info.recipient }}</div>
          </div>
          <!--      <div class="cell_item">-->
          <!--        <div class="item_title">档案接收单位邮编</div>-->
          <!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
          <!--      </div>-->
          <div class="cell_item">
            <div class="item_title">档案接收单位地址</div>
            <div class="item_value">{{ info.send_address }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">接收人联系电话</div>
            <div class="item_value">
              <div v-for="( items,indexs) in info.numlist" style="margin-top: 10px;">
                <a :href="'tel:' + items" class="flexStart" style="text-decoration: unset;color: unset;font-size: 16px;">
                  {{items}}
                  <img v-if="items" src="../../../../public/img/daInquiry_phone.png"
                       alt="" style="width: 20px;height: 20px;margin-left: 10px;"></a>
              </div>
<!--              {{ info.contact_number }}-->
            </div>
          </div>
          <!--      <div class="cell_item">-->
          <!--        <div class="item_title">手机号码</div>-->
          <!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
          <!--      </div>-->
          <div class="cell_item">
            <div class="item_title">EMS运单号</div>
            <div class="item_value flexStart">
              {{ info.mailing_num || '-' }}
              <img v-if="info.mailing_num&&info.mailing_num.length>0" src="../../../../public/img/daInquiry_copy.png"
                   alt="" style="width: 20px;height: 20px;margin-left: 10px;"
                   @click="onCopy">
            </div>
          </div>
        </div>
        <div v-if="type==2" class="" style="width: 100%;">
          <div class="groupTitle flexStart">
            <div class="border"></div>
            报到证查询信息
          </div>
          <div class="cell_item">
            <div class="item_title">学号</div>
            <div class="item_value">{{ info.STU_NUM }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">姓名</div>
            <div class="item_value">{{ info.STU_NAME }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">收件人</div>
            <div class="item_value">{{ info.recipient }}</div>
          </div>

          <div class="cell_item">
            <div class="item_title">收件地址</div>
            <div class="item_value">{{ info.send_address }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">联系电话</div>
            <div class="item_value">{{ info.phone }}</div>
          </div>

          <div class="cell_item">
            <div class="item_title">EMS运单号</div>
            <div class="item_value flexStart">
              {{ info.mailing_num || '-' }}
              <img v-if="info.mailing_num&&info.mailing_num.length>0" src="../../../../public/img/daInquiry_copy.png"
                   alt="" style="width: 20px;height: 20px;margin-left: 10px;"
                   @click="onCopy">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-else class="page_daInquiry flexCloumnSingleCenter">
      <div v-if="!isContent&&!info.STU_NAME" class="cell_group">
        <div class="cell_item">
          <div class="item_title">学号</div>
          <div class="item_value">
            <el-input v-model="query.stuNum" placeholder="学号"></el-input>

          </div>
        </div>
        <div class="cell_item">
          <div class="item_title">姓名</div>
          <div class="item_value">
            <el-input v-model="query.stuName" placeholder="姓名"></el-input>

          </div>
        </div>

        <div v-if="$route.query.school=='xwzz'" class="cell_item flexBetween">
          <div class="queryBtn plain flexCenter" @click="getList(1)" style="width: 45%;">档案查询</div>
          <div class="queryBtn plain flexCenter" @click="getList(2)" style="width: 45%;">报到证查询</div>
        </div>
        <div v-else class="cell_item flexBetween">
          <div class="queryBtn flexCenter" @click="getList()">查询</div>
        </div>
      </div>

      <div v-if="info.STU_NUM && info.STU_NAME" class="cell_group"
           style="margin-top: 20px;padding-bottom: 100px;">
        <div v-if="type==1" class="" style="width: 100%;">
          <div class="groupTitle flexStart">
            <div class="border"></div>
            档案查询信息
          </div>
          <div class="cell_item">
            <div class="item_title">学号</div>
            <div class="item_value">{{ info.STU_NUM }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">姓名</div>
            <div class="item_value">{{ info.STU_NAME }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">档案接收单位名称</div>
            <div class="item_value">{{ info.recipient }}</div>
          </div>
          <!--      <div class="cell_item">-->
          <!--        <div class="item_title">档案接收单位邮编</div>-->
          <!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
          <!--      </div>-->
          <div class="cell_item">
            <div class="item_title">档案接收单位地址</div>
            <div class="item_value">{{ info.send_address }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">接收人联系电话</div>
            <div class="item_value">
              <div v-for="( items,indexs) in info.numlist" style="margin-top: 10px;">
                <a :href="'tel:' + items" class="flexStart" style="text-decoration: unset;color: unset;font-size: 16px;">
                  {{items}}
                  <img v-if="items" src="../../../../public/img/daInquiry_phone.png"
                       alt="" style="width: 20px;height: 20px;margin-left: 10px;"></a>
              </div>
              <!--              {{ info.contact_number }}-->
            </div>
          </div>
          <!--      <div class="cell_item">-->
          <!--        <div class="item_title">手机号码</div>-->
          <!--        <div class="item_value">{{ info.STU_NAME }}</div>-->
          <!--      </div>-->
          <div class="cell_item">
            <div class="item_title">EMS运单号</div>
            <div class="item_value flexStart">
              {{ info.mailing_num || '-' }}
              <img v-if="info.mailing_num&&info.mailing_num.length>0" src="../../../../public/img/daInquiry_copy.png"
                   alt="" style="width: 20px;height: 20px;margin-left: 10px;"
                   @click="onCopy">
            </div>
          </div>
        </div>
        <div v-if="type==2" class="" style="width: 100%;">
          <div class="groupTitle flexStart">
            <div class="border"></div>
            报到证查询信息
          </div>
          <div class="cell_item">
            <div class="item_title">学号</div>
            <div class="item_value">{{ info.STU_NUM }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">姓名</div>
            <div class="item_value">{{ info.STU_NAME }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">收件人</div>
            <div class="item_value">{{ info.recipient }}</div>
          </div>

          <div class="cell_item">
            <div class="item_title">收件地址</div>
            <div class="item_value">{{ info.send_address }}</div>
          </div>
          <div class="cell_item">
            <div class="item_title">联系电话</div>
            <div class="item_value">{{ info.phone }}</div>
          </div>

          <div class="cell_item">
            <div class="item_title">EMS运单号</div>
            <div class="item_value flexStart">
              {{ info.mailing_num || '-' }}
              <img v-if="info.mailing_num&&info.mailing_num.length>0" src="../../../../public/img/daInquiry_copy.png"
                   alt="" style="width: 20px;height: 20px;margin-left: 10px;"
                   @click="onCopy">
            </div>
          </div>
        </div>

      </div>
    </div>

    <div id="toast" class="toast">11</div>

  </div>

</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      DataList: [],
      joined: false,
      viewResult: false,
      expired: false, //是否过期
      info: {},
      query: {},
      type: 1,
      isContent:'',
    }
  },
  mounted() {

  },
  methods: {
    onCopy() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.info.mailing_num; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功~");

    },

    getList(type) {
      this.info = {}
      let apiurl = ""
      if (this.$route.query.school == 'xwzz') {
        if (!this.query.className) {
          this.$message.warning("请输入所在班级~")
          return
        }
        if (!this.query.stuName) {
          this.$message.warning("请输入姓名~")
          return
        }
        const loading = this.$loading();

        if (type == 1) {
          this.type = 1
          apiurl = "/api/xwzz_ems_by_stuNum"
        }
        if (type == 2) {
          this.type = 2
          apiurl = "/api/xwzz_emsBdz_by_stuNum"
        }
        console.log(222, apiurl)

        this.$http.post(apiurl, {
          className: this.query.className,
          stuName: this.query.stuName,
        }).then((res) => {
          loading.close();

          if (res.data) {
            res.data.numlist = []
            if (res.data.contact_number) {
              if (res.data.contact_number.indexOf('、') > -1) {
                res.data.numlist = res.data.contact_number.split('、')
              }
              else if (res.data.contact_number.indexOf('，') > -1) {
                res.data.numlist = res.data.contact_number.split('，')
              }
              else {
                res.data.numlist = [res.data.contact_number]
              }
            }
            this.info = res.data;
            this.isContent = true
          }else {
            this.isContent = false
          }
          // if (res.data && res.data.mailing_num) {
          //   this.info = res.data;
          // } else {
          //   this.$message.error("抱歉，暂未查到运单号，请耐心等待~")
          // }
        })


        setTimeout(function () {
          loading.close();
        }, 2000)
      } else {

        if (!this.query.stuNum) {
          this.$message.warning("请输入学号~")
          return
        }
        if (!this.query.stuName) {
          this.$message.warning("请输入姓名~")
          return
        }
        const loading = this.$loading();

        apiurl = "/api/njwx_ems_by_stuNum"
        if (this.$route.query.school == 'xwzz') {
          if (type == 1) {
            this.type = 1
            apiurl = "/api/xwzz_ems_by_stuNum"
          }
          if (type == 2) {
            this.type = 2
            apiurl = "/api/xwzz_emsBdz_by_stuNum"
          }
        }
        console.log(222, apiurl)

        this.$http.post(apiurl, {
          stuNum: this.query.stuNum,
          stuName: this.query.stuName,
        }).then((res) => {
          loading.close();
          if (res.data) {
            res.data.numlist = []
            if (res.data.contact_number) {
              if (res.data.contact_number.indexOf('、') > -1) {
                res.data.numlist = res.data.contact_number.split('、')
              }
              else if (res.data.contact_number.indexOf('，') > -1) {
                res.data.numlist = res.data.contact_number.split('，')
              }
              else {
                res.data.numlist = [res.data.contact_number]
              }
            }
            this.info = res.data;
            this.isContent = true
          }else {
            this.isContent = false
          }
          // if (res.data && res.data.mailing_num) {
          //   this.info = res.data;
          // } else {
          //   this.$message.error("抱歉，暂未查到运单号，请耐心等待~")
          // }
        })


        setTimeout(function () {
          loading.close();
        }, 2000)
      }

    },

    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function () {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function () {
            $("#toast").hide()
          })
        }, 2000)
      })
    },
  }
}
</script>

<style scoped lang="less" type="text/less">
.page_daInquiry {
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #f2f4f8;
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  .groupTitle {
    padding: 15px 0;

    .border {
      display: inline-block;
      height: 20px;
      width: 8px;
      background-color: #409eff;
      border-radius: 4px;
      margin-right: 6px;

    }
  }

  .cell_group {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 5px #ccc;
    background-color: #fff;
    padding: 0 20px;

    .cell_item {
      border-bottom: 1px solid #efefef;
      padding: 15px 0;

      .item_title {
        color: #333333;
        font-size: 14px;
      }

      .item_value {
        margin-top: 10px;
        //padding-left: 4px;
        color: #909399;
        font-size: 16px;
      }
    }

    .cell_item:last-of-type {
      border: 0;
    }

    .queryBtn {
      cursor: pointer;
      padding: 10px 0;
      width: 100%;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
      background-color: #409eff;
    }

    .plain {
      color: #409eff;
      background-color: #409eff51;
      border: 1px solid #409eff;
    }
  }
}

.required {
  color: orangered;
}

.notrequired {
  color: #FFFFFF;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0;
  display: none;
}
</style>
